import axios from 'axios';
import router from '@/router';
import {useUserStore} from "@/store/modules/user";

const baseDomain = process.env.VUE_APP_API_URL;
const baseURL = `${baseDomain}/api`;

const ax = axios.create({
  baseURL,
});

// Add a request interceptor
ax.interceptors.request.use((request) => {
  const userStore = useUserStore();

  if (localStorage.token) {
    request.headers.Authorization = `${userStore.tokenType} ${userStore.token}`;
    return request;
  }
  return request;
});

ax.interceptors.response.use((response) => {
  // Any status code that lie within the range of 2xx cause this function to trigger
  // Do something with response data
  return response;
}, (error) => {
  // Any status codes that falls outside the range of 2xx cause this function to trigger
  // Do something with response error
  if (error.response.status === 401) {
    if (process.env.VUE_APP_DEMO_MODE) {
      return;
    }
    router.push('/callback');
  }
  return error;
});

export default ax;
