export default {
  languageName: 'українська',
  logout: 'вихід',
  logoutGoogleAccount: 'вихід з google аккаунта',
  signInWithGoogle: 'Увійти через Google',
  confRoomMenu: {
    book: 'зарезервувати',
  },
  search: {
    placeholder: {
      full: 'Пошук по іменам, технологіям',
      small: 'Пошук',
    },
    sort: {
      label: 'Сортувати за:',
      type: {
        'floor': 'поверхом',
        'experience': 'досвідом',
        'nearest birthday': 'найближчим днем народження',
      },
    },
    noMatches: 'Не знайдено жодного результату',
  },
  language: 'мова | мови',
  floor: 'поверх | поверхи',
  freeSeats: 'вільних місць',
  remote: 'віддалено',
  moveTo: 'перенести',
  left: 'ліворуч',
  right: 'праворуч',
  people: 'люди',
  technology: 'технологія | технології',
  welcomeTo: 'Ласкаво просимо до',
  loginWith: 'Вхід через',
  poweredBy: 'Розроблено в',
  conferenceRoom: 'переговорка | переговорки',
  standingTables: 'столики',
  place: 'місце | локації',
  vacation: 'у відпусці',
  inOffice: 'в офісі',
  copy: 'копіювати',
  unknown: 'невідомо',
  birthday: 'день народження',
  workStart: 'початок роботи',
  myProfile: 'мій профіль',
  till: 'до',
  timeList: 'список доступності',
  permissionGapiError: 'Ви не маєте дозволу на доступ до цього календаря.',
  generalGapiError: 'Внутрішня помилка сервера. Спробуйте пізніше або зв\'яжіться з нами',
  all: 'Усі',
};
