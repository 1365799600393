import { createApp } from 'vue';
import { createPinia } from "pinia";
import App from './App.vue';
import './assets/scss/style.scss';
import './registerServiceWorker';
import router from './router';
import VueScrollTo from 'vue-scrollto';
import VueGtag from 'vue-gtag';
import i18n from '@/translations/i18n';
import Notifications from '@kyvg/vue3-notification';
import "broadcastchannel-polyfill";
import mitt from "mitt";
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'
import { PiniaSharedState } from 'pinia-shared-state';

const emitter = mitt();

const pinia = createPinia()

pinia.use(piniaPluginPersistedstate);
pinia.use(
    PiniaSharedState({
      initialize: true,
      type: 'localstorage',
    }),
);

const app = createApp(App);

app.provide('emitter', emitter);

app.use(VueGtag, {
  config: {
    id: process.env.VUE_APP_GOOGLE_ANALYTICS_ID
  },
  pageTrackerScreenviewEnabled: true
});
app.use(VueScrollTo);
app.use(Notifications);

// app.config.productionTip = false;
app.config.warnHandler = (msg, vm, trace) => {
  // Обработка предупреждений или их игнорирование
};


app
    .use(pinia)
    .use(router)
    .use(i18n)
    .mount("#app");
