import {CurrentUserInterface} from '@/interfaces/currentUserInterface';
import passportApi from '@/service/passportApi';
import {LocaleEnum} from '@/enums/LocaleEnum';
import me from '@/data/me';
import {defineStore} from 'pinia';
import {ref} from "vue";

export const useUserStore = defineStore("user", () => {
  let currentUser = ref({});
  let tokenType: any = ref(localStorage.token ? JSON.parse(localStorage.token).token_type : '');
  let token: any = ref(localStorage.token ? JSON.parse(localStorage.token).access_token : '');
  let initToken: any = ref('');
  let locale = LocaleEnum.En;
  const isTouchDevice = ref(false);
  const isSignInGoogleAccount = ref(false);

  const setCurrentUser = (user: CurrentUserInterface) => {
    currentUser.value = user;
  }

  const setToken = (tokenInput: string) => {
    token.value = tokenInput;
  }


  const setInitToken = (initTokenInput: string) => {
    initToken.value = initTokenInput;
  }

  const setTokenType = (tokenTypeInput: string) => {
    tokenType.value = tokenTypeInput;
  }

  const setLocale = (localeInput: LocaleEnum) => {
    locale = localeInput;
  }

  const changeTouchDevice = (isTouch: boolean) => {
    isTouchDevice.value = isTouch;
  }

  const setSignInGoogleAccount = (value: boolean) => {
    isSignInGoogleAccount.value = value;
  }

  const getCurrentUser = async () => {
    if (process.env.VUE_APP_DEMO_MODE) {
      return me;
    }
    return passportApi.getCurrentEmployee().then((res) => {
      if (res.data) {
        setCurrentUser(res.data);
      }
    });
  }

  return {
    currentUser,
    tokenType,
    token,
    initToken,
    locale,
    isTouchDevice,
    isSignInGoogleAccount,
    setToken,
    setInitToken,
    setTokenType,
    setLocale,
    changeTouchDevice,
    setSignInGoogleAccount,
    getCurrentUser,
  }
}, {
  persist: true,
  share: {
    enable: true,
    initialize: true,
  }
})
